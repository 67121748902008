import React, { useEffect, useState } from 'react';
import {
	Selection,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryLabel,
	VictoryLine,
	VictoryTooltip,
} from 'victory';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Logo from 'assets/images/branding/logo.svg';
import {
	Accordion,
	Button,
	ButtonStack,
	ChartLegend,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
	Tooltip,
} from 'components';
import { border, fontFamily, getColor, getFacility } from 'utils';
import { useAppSelector, useGetTurnoverQuery, useSystem } from 'store';
import { useFilters, useToast } from 'context';

import type { TurnoverData } from 'store';

const legend = [
	{
		label: 'Off Target',
		color: getColor('yellow'),
	},
	{
		label: 'On Target',
		color: getColor('green'),
	},
];

const legendBothTurnovers = [
	{
		label: 'Patient In/Out Turnover',
		color: getColor('blue-500'),
	},
	{
		label: 'Procedure Start/Stop Turnover',
		color: getColor('blue-400'),
	},
];

const sortByOptions = [
	{
		label: 'Time (newest-oldest)',
		value: 'date_descending',
	},
	{
		label: 'Time (oldest-newest)',
		value: 'date_ascending',
	},
	{
		label: 'Avg Minutes (low-high)',
		value: 'avg_ascending',
	},
	{
		label: 'Avg Minutes (high-low)',
		value: 'avg_descending',
	},
	{
		label: '# of Turnovers (low-high)',
		value: 'count_ascending',
	},
	{
		label: '# of Turnovers (high-low)',
		value: 'count_descending',
	},
	{
		label: 'Alphabetical (a-z)',
		value: 'alphabetical_ascending',
	},
	{
		label: 'Alphabetical (z-a)',
		value: 'alphabetical_descending',
	},
];

const groupByOptions = [
	{
		label: 'None',
		value: 'none',
	},
	{
		label: 'Month',
		value: 'month',
	},
	{
		label: 'Year',
		value: 'year',
	},
];

const viewByOptions = [
	{
		label: 'Day of Week',
		value: 'day_of_week',
	},
	{
		label: 'Week',
		value: 'week_of_year',
	},
	{
		label: 'Month',
		value: 'month',
	},
	{
		label: 'Quarter',
		value: 'quarter',
	},
	{
		label: 'Surgeon',
		value: 'surgeon_id',
	},
	{
		label: 'Service Line',
		value: 'service_line_id',
	},
	{
		label: 'Room',
		value: 'room_id',
	},
];

const INIT_PRINTABLE_HEADER_HEIGHT = 54;
const ROWS_PER_PAGE = 15;
const PRINTABLE_BATCH_SIZE = 18;

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: {
		number_of_wheels_turnovers?: number | undefined;
		number_of_proc_turnovers?: number | undefined;
		y: number;
		x: string;
	};
}> {
	render() {
		const { x2, y2, datum } = this.props;
		return (
			<foreignObject x={x2} y={y2} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-20'>
					<div className='bg-white p-1 px-3 pl-1'>
						<p className='text-left text-[0.27em] font-semibold'>{datum?.x}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Turnover Average</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.y}mins</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'># of Turnovers</p>
						<p className='text-right text-[0.25em] text-white'>
							{datum?.number_of_wheels_turnovers ? datum?.number_of_wheels_turnovers : datum?.number_of_proc_turnovers ?? 0}
						</p>
					</div>
				</div>
			</foreignObject>
		);
	}
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	datum?: {
		number_of_wheels_turnovers?: number | undefined;
		number_of_proc_turnovers?: number | undefined;
		y: number;
		x: string;
	};
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							// The label will not change position, but the tooltip will change position
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2 } = this.props;
		return (
			<g>
				<VictoryLabel {...this.props} verticalAnchor='middle' dx={5} style={{ fontFamily: fontFamily, fontSize: 5 }} />
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} />}
					style={{ fontSize: 0 }}
				/>
			</g>
		);
	}
}

export function Turnover() {
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);

	const { data: systemData } = useSystem();
	const facilities = systemData?.facilities ?? [];
	const facility = getFacility(facilities, selectedFacility ?? 0);
	//get target based on facility settings
	const target = facility === false ? 0 : facility.turnover_target;
	const currentFacility = facilities?.find((f) => f.id === selectedFacility);
	const system = systemData?.healthsystems.find((h) => h.id === selectedSystem);
	const [showTurnover, setShowTurnover] = useState<string[]>(['wheels']);
	const updateShowTurnover = (option: string) => {
		if (!showTurnover.includes(option)) {
			setShowTurnover([...showTurnover, option]);
		} else {
			if (showTurnover.length > 1) {
				setShowTurnover(showTurnover.filter((item) => item !== option));
			}
		}
	};

	// Filters
	const {
		dateRange,
		surgeons,
		daysOfWeek,
		serviceLines,
		encounterTypes,
		rooms,
		procedures,
		wheelsTurnoverTimeThreshold,
		procTurnoverTimeThreshold,
		primetime,
		addOns,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		currentPageLoaded,
		metadata,
		filtersAreFetching,
	} = useFilters();

	// Used to hide certain elements printing purposes
	const [searchParams] = useSearchParams();
	const printable = searchParams.get('printable') === 'true';

	// default: view by month
	const selectedViewBy = !printable
		? dropDowns.viewBy.value !== 'undefined'
			? dropDowns.viewBy
			: viewByOptions[2]
		: {
				label: searchParams.get('view_by_label') ?? viewByOptions[1].label,
				value: searchParams.get('view_by') ?? viewByOptions[1].value,
		  };
	// default: sort by time, descending (newest data -> oldest data)
	const selectedSortBy = !printable
		? dropDowns.sortBy.value !== 'undefined'
			? dropDowns.sortBy
			: sortByOptions[0]
		: {
				label: searchParams.get('sort_by_label') ?? sortByOptions[0].label,
				value: searchParams.get('sort_by') ?? sortByOptions[0].value,
		  };
	// default: group by year
	const selectedGroupBy = !printable
		? dropDowns.groupBy.value !== 'undefined'
			? dropDowns.groupBy
			: groupByOptions[2]
		: {
				label: searchParams.get('group_by_label') ?? groupByOptions[0].label,
				value: searchParams.get('group_by') ?? groupByOptions[0].value,
		  };
	let filteredSortByOptions = sortByOptions.slice(0, sortByOptions.length - 2);
	let filteredGroupByOptions = [groupByOptions[0], groupByOptions[2]];

	const turnoverTitleMiddleText = () => {
		if (showTurnover.includes('wheels') && showTurnover.includes('procedure')) {
			return 'The bars show a comparison between two computations:\n 1) when a patient leaves the room until the next patient enters the room and \n2) when a procedure stops until the next one starts. ';
		} else if (showTurnover.includes('wheels')) {
			return 'The computation is based on when a patient leaves the room (wheels out) until the next patient enters the room (wheels in). ';
		} else {
			return 'The computation is based on when a procedure stops (procedure_stop) until the next one starts (procedure_start). ';
		}
	};

	const title =
		selectedViewBy.value !== 'surgeon_id' && selectedViewBy.value !== 'service_line_id' ? (
			<div>
				<b>Average Turnover Time - Room </b>
				<div className='font-light text-[0.8em]'>
					This measures the amount of time a room does not have a patient between surgeries. {turnoverTitleMiddleText()}
					This data follows the events occurring in each room regardless of which surgeon completed the case.
				</div>
			</div>
		) : (
			<div>
				<b>Average Turnover Time - Surgeon </b>
				<div className='font-light text-[0.8em]'>
					This measures the amount of time a surgeon is not operating between surgeries. {turnoverTitleMiddleText()} This
					data follows the surgeon regardless of which room the case happened in.
				</div>
			</div>
		);

	const tooltipText =
		selectedViewBy.value !== 'surgeon_id' && selectedViewBy.value !== 'service_line_id'
			? `Use this to understand your room turnover performance across various categories, like time and room. In general,
							lower average turnover time may signify more efficient use of downtime between cases.`
			: `Use this to understand your room turnover performance across various categories, like time, surgeon, and service
							line. In general, lower average turnover time may signify more efficient use of downtime between cases.`;

	let groupbyArg;
	// if viewby is changed, we need to limit the sortby options
	if (selectedViewBy.value === 'day_of_week') {
		filteredGroupByOptions = [groupByOptions[0], groupByOptions[1], groupByOptions[2]];
		groupbyArg = groupByOptions[2].value;
	} else if (selectedViewBy.value === 'week_of_year') {
		filteredGroupByOptions = [groupByOptions[2]];
		groupbyArg = groupByOptions[2].value;
	} else if (selectedViewBy.value === 'month' || selectedViewBy.value === 'quarter') {
		filteredGroupByOptions = [groupByOptions[0], groupByOptions[2]];
		groupbyArg = groupByOptions[2].value;
	} else {
		filteredGroupByOptions = [groupByOptions[0]];
		groupbyArg = groupByOptions[0].value;

		const options = sortByOptions.slice(2, sortByOptions.length);
		filteredSortByOptions = options;
	}

	// if the groupby changed, we need to update the sortby
	if (
		(selectedGroupBy.value === 'month' ||
			selectedGroupBy.value === 'year' ||
			selectedGroupBy.value === 'quarter' ||
			selectedGroupBy.value === 'day_of_week') &&
		groupbyArg === 'none'
	) {
		const options = sortByOptions.slice(2, sortByOptions.length - 2);
		filteredSortByOptions = options;
	} else {
		const options = sortByOptions.slice(0, sortByOptions.length - 2);
		filteredSortByOptions = options;
	}

	let filteredViewByOptions = viewByOptions;

	if (selectedGroupBy.value === 'none') {
		// filteredViewByOptions do not show index 1, remove week
		filteredViewByOptions = viewByOptions.filter((option, i) => i !== 1);
	}

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/turnover') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const {
		data: turnoverData,
		isFetching,
		error,
	} = useGetTurnoverQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
			sortby: selectedSortBy.value,
			groupby: selectedGroupBy.value,
			viewby: selectedViewBy.value,
			filters: {
				surgeons: surgeons?.applied,
				days_of_week: daysOfWeek?.applied,
				service_lines: serviceLines?.applied,
				encounter_types: encounterTypes?.applied,
				rooms: rooms?.applied,
				procedures: procedures?.applied.map((procedure) => procedure.name),
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				wheels_turnover_threshold: wheelsTurnoverTimeThreshold?.applied,
				primetime: primetime?.applied,
				add_ons: addOns?.applied,
			},
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	// Handle api response to create toast message on every error.
	const { createToast } = useToast();
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
	}, [createToast, error]);

	let exportData = [];
	if (turnoverData?.data) {
		exportData = turnoverData.data
			.map((group) => {
				const items = group.wheels_tot_values.map((v) => {
					// we need any for exporting here
					// eslint-disable-next-line
					const row: any = {
						value: v.y,
					};
					const key = selectedViewBy.value.replace('_id', '');
					row[key] = v.x;
					if (selectedGroupBy.value !== 'none') {
						row[selectedGroupBy.value] = group.groupValue;
					}
					return row;
				});

				return items;
			})
			.flat();
	}

	// check the "Group by" selection in the dropdown, and do some smart grouping here based on that
	// need to "group" each type of data that can receive it
	const isGrouped = selectedGroupBy?.value !== 'none' || printable;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const data: TurnoverData[] = printable ? printable_format(turnoverData?.data ?? []) : turnoverData?.data ?? [];

	// const proc_turnover_data: TurnoverData[] = printable
	// 	? printable_format(turnoverData?.proc_turnover_data ?? [])
	// 	: turnoverData?.proc_turnover_data ?? [];

	// Checks if printable page version, then print
	useEffect(() => {
		const generatePDF = async (rows: number) => {
			let newPage = false;
			const doc = new jsPDF('p', 'pt', 'letter');
			let prev_height = INIT_PRINTABLE_HEADER_HEIGHT;
			let rows_printed = 0;
			const header = document.getElementById(`header`);

			if (header) {
				const header_canvas = await html2canvas(header, {
					scale: 2,
				});
				const header_imgData = header_canvas.toDataURL('image/jpeg');
				doc.addImage(header_imgData, 'PNG', 10, 10, 590, 24);
			}

			for (let i = 0; i < data.length; i += 1) {
				// Get the desired element to be captured
				const accordion = document.getElementById(`accord-${i}`);
				rows_printed += data[i].wheels_tot_values.length;

				if (accordion) {
					// Render the content in a canvas
					const canvas = await html2canvas(accordion, {
						scale: 2,
					});

					// Add the canvas image to the PDF
					const imgData = canvas.toDataURL('image/jpeg');

					if (newPage) {
						doc.addPage();
						newPage = false;
						prev_height = 10;
					}

					// Adding chart
					doc.addImage(imgData, 'PNG', 10, 10 + prev_height, 590, accordion.clientHeight / 2.6);
					prev_height += accordion.clientHeight / 2.7;

					if (rows_printed >= ROWS_PER_PAGE) {
						newPage = true;
						rows_printed = 0;
					}
				}
			}

			// Adding active filters
			const active_filters = document.getElementById('active-filters');
			if (active_filters) {
				const canvas_3 = await html2canvas(active_filters, {
					scale: 2,
				});
				const imgData_3 = canvas_3.toDataURL('image/jpeg');
				doc.addPage();
				doc.addImage(imgData_3, 'PNG', 10, 10, 590, 770);
			}

			// Save the generated PDF
			doc.save('turnover.pdf');
			window.close();

			// if (printable && turnoverData && !(isFetching || skipRequest)) {
			setTimeout(() => {
				generatePDF(data.map((row) => row.wheels_tot_values.length).reduce((a, b) => a + b, 0));
			}, 0);
		};
	}, [data, isFetching, isGrouped, printable, skipRequest, turnoverData]);

	const showLegend = () => {
		if (showTurnover.length === 1 && showTurnover[0] === 'procedure') {
			return [
				{
					label: 'Procedure Turnover',
					color: getColor('blue-400'),
				},
			];
		} else if (showTurnover.length < 2) {
			return legend;
		} else {
			return legendBothTurnovers;
		}
	};

	const maxDomain = data.reduce((max, item) => {
		return Math.max(
			max,
			Math.max(...item.proc_tot_values.map((v) => v.y), Math.max(...item.wheels_tot_values.map((v) => v.y)))
		);
	}, Number.NEGATIVE_INFINITY);

	return (
		<div className='relative'>
			{!printable && (
				<div className='flex justify-between'>
					<PageHeading>Turnover Time Overview</PageHeading>
				</div>
			)}

			<div className='relative'>
				{(isFetching || skipRequest || filtersAreFetching) && <LogoOverlay backgroundColor='white' />}
				<Panel
					title={title}
					defaultTitleClass={''}
					tooltipContent={tooltipText}
					goToHelpID='turnovertime'
					headerContentRight={
						!printable ? (
							<>
								<div className='flex justify-end pb-3'>
									<div className='pr-3'>
										<Tooltip content={'Patient In/Out Turnover'}>
											<Button
												sizeX='square'
												sizeY='md'
												variant={showTurnover.includes('wheels') ? 'primary' : 'primary-ghost'}
												className='border-r-0 rounded-none'
												onClick={() => updateShowTurnover('wheels')}
											>
												<span className='material-symbols-outlined'>single_bed</span>
											</Button>
										</Tooltip>
										<Tooltip content={'Procedure Start/Stop Turnover'}>
											<Button
												sizeX='square'
												sizeY='md'
												// disabled={!has_scheduled}
												variant={showTurnover.includes('procedure') ? 'primary' : 'primary-ghost'}
												className='border-l-[0.01em]'
												onClick={() => updateShowTurnover('procedure')}
											>
												<span className='material-symbols-outlined'>surgical</span>
											</Button>
										</Tooltip>
									</div>
									<Drawer
										metadata={metadata}
										filtersAreDirty={filtersAreDirty}
										trigger={
											<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
												<span className='material-symbol'>filter_alt</span>
												Filters
											</Button>
										}
										quickActions={[
											{
												icon: 'undo',
												onClick: resetFilters,
												tooltipText: 'Discard unapplied filter changes',
												disabled: !filtersAreDirty,
											},
											{
												icon: 'restart_alt',
												onClick: clearFilters,
												tooltipText: 'Reset filters to default',
												disabled: !metadata.saved_at,
											},
										]}
										actionButtons={[
											{
												onClick: applyFilters,
												children: 'Apply',
												disabled: !filtersAreDirty,
											},
											{
												// eslint-disable-next-line @typescript-eslint/no-empty-function
												onClick: () => {},
												children: 'Views',
												disabled: false,
											},
										]}
									>
										<FilterFields
											fields={[
												'dateRange',
												'surgeons',
												'daysOfWeek',
												'serviceLines',
												'encounterTypes',
												'rooms',
												'procedures',
												'wheelsTurnoverTimeThreshold',
												'primetime',
												'addOns',
											]}
										/>
									</Drawer>
									<div>
										<ButtonStack>
											<Button sizeX='md' sizeY='md' variant='primary'>
												<ExportButton no_button={true} sizeX='md' sizeY='md' variant='primary' contents={exportData}>
													Export CSV
												</ExportButton>
											</Button>

											<Button
												sizeX='md'
												sizeY='md'
												variant='primary'
												onClick={() => {
													window.open(
														`/turnover?printable=true&view_by=${selectedViewBy.value}&view_by_label=${selectedViewBy.label}&sort_by=${selectedSortBy.value}&sort_by_label='${selectedSortBy.label}'&group_by_label=${selectedGroupBy.label}&group_by=${selectedGroupBy.value}`
													);
												}}
											>
												Export PDF
											</Button>
										</ButtonStack>
									</div>
								</div>
							</>
						) : (
							<div className='flex text-p1 w-screen justify-between' id='header'>
								<div className='flex text-center w-1/5'>
									<div className='pt-5 pl-8'>
										<img className='h-11 w-11 relative -left-[2px]' src={Logo} alt='Merlin' />
									</div>
									<p className='pt-5 mt-1 pl-3 w-16 whitespace-nowrap text-h5 font-semibold'>Turnover Overview</p>
								</div>

								<div className='flex flex-row justify-end w-3/4 mt-2 pr-4'>
									<div className='px-4 py-2 bg-blue-200 w-fit rounded-md self-center mr-6'>
										{`${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
									</div>
									<div className='px-4 py-2 bg-blue-200 w-fit rounded-md self-center'>
										{`${
											currentFacility?.intraop_facility_name_alias
												? currentFacility.intraop_facility_name_alias
												: currentFacility?.name
										} - ${system?.name}`}
									</div>
								</div>
							</div>
						)
					}
					isEmpty={turnoverData?.data?.length === 0}
					className={`${printable && 'border-none pb-3 text-h4'}`}
				>
					<div className='flex justify-between items-center'>
						<div className='flex gap-4'>
							<div className='w-36'>
								<Select
									label='View by'
									onChange={(selected) => {
										if (selected) {
											const optionInViewBy = viewByOptions.find((element) => element.value === selected.value);
											if (optionInViewBy) {
												// if viewby is changed, we need to limit the sortby options
												let newGroupBy;
												let newSortBy = selectedSortBy;
												if (optionInViewBy.value === 'day_of_week') {
													newGroupBy = groupByOptions[2];
												} else if (
													optionInViewBy.value === 'month' ||
													optionInViewBy.value === 'quarter' ||
													optionInViewBy.value === 'week_of_year'
												) {
													newGroupBy = groupByOptions[2];
												} else {
													newGroupBy = groupByOptions[0];
													newSortBy = sortByOptions[3];
												}

												dropDowns.update({ ...dropDowns, viewBy: optionInViewBy, sortBy: newSortBy, groupBy: newGroupBy });
												saveDropdown({ ...dropDowns, viewBy: optionInViewBy, sortBy: newSortBy, groupBy: newGroupBy });
											}
										}
									}}
									value={selectedViewBy}
									options={filteredViewByOptions}
									defaultValue={selectedViewBy}
								/>
							</div>
							<div className='w-64'>
								<Select
									label='Sort by'
									options={filteredSortByOptions}
									value={selectedSortBy}
									onChange={(selected) => {
										if (selected) {
											dropDowns.update({ ...dropDowns, sortBy: selected });
											saveDropdown({ ...dropDowns, sortBy: selected });
										}
									}}
								/>
							</div>
							<div className='w-36'>
								<Select
									label='Group by'
									options={filteredGroupByOptions}
									value={selectedGroupBy}
									onChange={(selected) => {
										if (selected) {
											let newSortBy;
											if (
												(selectedGroupBy.value === 'month' ||
													selectedGroupBy.value === 'year' ||
													selectedGroupBy.value === 'quarter' ||
													selectedGroupBy.value === 'day_of_week') &&
												selected.value === 'none'
											) {
												newSortBy = sortByOptions[3];
											} else {
												newSortBy = sortByOptions[0];
											}
											dropDowns.update({ ...dropDowns, groupBy: selected, sortBy: newSortBy });
											saveDropdown({ ...dropDowns, groupBy: selected, sortBy: newSortBy });
										}
									}}
								/>
							</div>
						</div>
						<div>
							<ChartLegend options={showLegend()} />
						</div>
					</div>
					<div className='max-w-screen-2xl m-auto'>
						{isGrouped && data ? (
							<Accordion className='my-8' type='multiple' defaultValue={data.map((group) => `${group.groupValue}`)}>
								{data.map((group, i) => {
									return (
										<Accordion.Item key={i} value={`${group.groupValue}`} dark id={`accord-${i}`} printable={printable}>
											<TurnoverBarChart
												wheels_turnover_values={group.wheels_tot_values}
												proc_turnover_values={group.proc_tot_values}
												target={target}
												maxDomain={maxDomain + 5}
												printable={printable}
												showTurnover={showTurnover}
											/>
										</Accordion.Item>
									);
								})}
							</Accordion>
						) : (
							data.map((group, i) => {
								return (
									<TurnoverBarChart
										key={i}
										wheels_turnover_values={group.wheels_tot_values}
										proc_turnover_values={group.proc_tot_values}
										target={target}
										maxDomain={maxDomain + 5}
										printable={printable}
										showTurnover={showTurnover}
									/>
								);
							})
						)}
					</div>
				</Panel>
			</div>
			{printable && (
				<div id={`active-filters`} className='h-[125em] pl-12 pt-12 pr-12'>
					<h1 className='text-h2 font-bold'>Active Filters</h1>
					{[
						{
							name: 'Date Range',
							context: `${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`,
							type: 'static',
						},
						{
							name: 'Patient In/Out Turnover Time Threshold',
							context: `${wheelsTurnoverTimeThreshold.applied} mins`,
							type: 'static',
						},
						{ name: 'Primetime Setting', context: `${primetime.applied}`, type: 'static' },
						{ name: 'Add-On Setting', context: `${addOns.applied}`, type: 'static' },
						{ name: 'Included Surgeons', context: surgeons.applied, type: 'array' },
						{ name: 'Included Rooms', context: rooms.applied, type: 'array' },
						{ name: 'Included Encounter Types', context: encounterTypes.applied, type: 'array' },
						{ name: 'Included Service Lines', context: serviceLines.applied, type: 'array' },
						{ name: 'Included Days of Week', context: daysOfWeek.applied, type: 'array' },
					].map((filter, i) => {
						return (
							<div key={i} className='flex flex-row flex-wrap items-center'>
								<h3 className='px-4 py-2 bg-blue-200 text-p1 w-fit rounded-md mt-6 self-center mr-6'>{filter.name}</h3>

								{typeof filter.context == 'object' ? (
									<div className='flex flex-row flex-wrap'>
										{filter.context.slice(0, 40).map((option, i) => (
											<div key={i} className='px-4 py-2 mr-2 border border-blue-300 text-p2 w-fit rounded-md mt-6 self-center'>
												{typeof option == 'string' ? option : option.name}
											</div>
										))}
										{filter.context.slice(0, 40).length === 40 && (
											<div className='px-4 py-2 mr-2 font-semibold text-p2 w-fit rounded-md mt-6 self-center'>{`+ ${
												filter.context.length - filter.context.slice(0, 40).length
											} ....`}</div>
										)}
									</div>
								) : (
									<p className='pt-2'>{`${filter.context}`}</p>
								)}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default Turnover;

interface TurnoverBarChartsProps {
	wheels_turnover_values: TurnoverData['wheels_tot_values'];
	proc_turnover_values: TurnoverData['proc_tot_values'];
	target: number;
	maxDomain: number;
	printable?: boolean;
	showTurnover: string[];
}

// determines vertical spacing between bars
function determineHeight(valuesLength: number) {
	if (valuesLength <= 5) {
		return 40 * valuesLength;
	} else if (valuesLength > 5) {
		return 25 * valuesLength;
	} else if (valuesLength > 10) {
		return 14 * valuesLength;
	} else {
		return 28 * valuesLength;
	}
}

function TurnoverBarChart({
	wheels_turnover_values,
	proc_turnover_values,
	target,
	maxDomain,
	printable,
	showTurnover,
}: TurnoverBarChartsProps) {
	const axisFontSize = 5;

	const valuesAxis = {
		axis: { stroke: border },
		tickLabels: { fontFamily: fontFamily, fontSize: axisFontSize, padding: 5 },
	};

	const viewByAxis = {
		axis: { stroke: border },
		grid: { stroke: border },
		tickLabels: { fontFamily: fontFamily, fontSize: axisFontSize, padding: 5 },
	};
	const avg = Math.round(
		wheels_turnover_values.reduce((r, c) => r + c.x.toString().length, 0) / wheels_turnover_values.length
	);
	const regx = new RegExp('.{1,avg}(?:\\s|$)'.replace('avg', avg > 20 ? (avg - 2).toString() : '18'), 'g');
	console.log(showTurnover);
	console.log(showTurnover.length);
	console.log(showTurnover[0]);
	const wheelsFillColor = (datum: any) => {
		if (showTurnover.length === 2) {
			return getColor('blue-500');
		}

		if (datum && datum.y > target) {
			return getColor('yellow');
		} else {
			return getColor('green');
		}
	};
	return (
		<VictoryChart
			height={
				showTurnover.length > 1
					? determineHeight(wheels_turnover_values.length) + 18
					: determineHeight(wheels_turnover_values.length)
			}
			padding={{ top: 20, bottom: 20, left: avg > 20 ? avg * 2.8 : 65, right: 10 }}
			domain={{ y: [0, maxDomain] }}
			horizontal
			domainPadding={10}
		>
			<VictoryAxis offsetY={12} dependentAxis style={viewByAxis} tickFormat={(t) => `${t}`} />
			<VictoryAxis style={valuesAxis} tickFormat={(t) => t.match(regx)} />
			<VictoryGroup offset={wheels_turnover_values.length < 6 ? 15 : 9}>
				{showTurnover.includes('wheels') && (
					<VictoryBar
						data={wheels_turnover_values}
						// we noticed VictoryBar was not preserving the sort order from the endpoint so we are sorting values here
						// we are sorting values one way in the backend and sorting again here...maybe we can get around this behavior in the future
						sortKey='x'
						sortOrder='descending'
						cornerRadius={2}
						barWidth={wheels_turnover_values.length > 5 ? 8 : 12.5}
						style={{
							data: {
								fill: ({ datum }) => {
									return wheelsFillColor(datum);
								},
							},
						}}
						labels={wheels_turnover_values.map(
							(v) => `${v.y} mins ${printable ? `| ${v.number_of_wheels_turnovers} Turnovers` : ''}`
						)}
						labelComponent={<CustomLabel />}
					/>
				)}
				{showTurnover.includes('procedure') && (
					<VictoryBar
						data={proc_turnover_values}
						// we noticed VictoryBar was not preserving the sort order from the endpoint so we are sorting values here
						// we are sorting values one way in the backend and sorting again here...maybe we can get around this behavior in the future
						sortKey='x'
						sortOrder='descending'
						cornerRadius={2}
						barWidth={proc_turnover_values.length > 5 ? 8 : 12.5}
						style={{
							data: {
								fill: () => {
									return getColor('blue-400');
								},
							},
						}}
						labels={proc_turnover_values.map(
							(v) => `${v.y} mins ${printable ? `| ${v.number_of_proc_turnovers} Turnovers` : ''}`
						)}
						labelComponent={<CustomLabel />}
					/>
				)}
			</VictoryGroup>
			{showTurnover.length === 1 && showTurnover[0] === 'wheels' && (
				<VictoryLine
					y={() => target}
					style={{
						data: { stroke: '#000033', strokeWidth: 0.25, strokeDasharray: 1 },
						parent: { border: '0.5px solid #ccc' },
					}}
				/>
			)}
			{showTurnover.length === 1 && showTurnover[0] === 'wheels' && (
				<VictoryAxis
					dependentAxis
					orientation='top'
					tickValues={[target]}
					tickFormat={(targetPercent) => `Target - ${Math.round(targetPercent)} min`}
					style={{ axis: { strokeWidth: 0 }, tickLabels: { padding: 5 } }}
					tickLabelComponent={
						<VictoryLabel
							lineHeight={[1.75, 1.25, 1.25]}
							style={{ fontSize: 4, fontFamily: fontFamily, fontWeight: 'bold' }}
						/>
					}
					animate
				/>
			)}
		</VictoryChart>
	);
}

interface PrintableTable {
	g: string | number | null | undefined;
	wheels_tot_value: {
		x: string | number;
		y: number;
		number_of_wheels_turnovers: number;
	};
	proc_tot_value: {
		x: string | number;
		y: number;
		number_of_proc_turnovers: number;
	};
}

function printable_format(data: TurnoverData[]) {
	const bigWheelsArray: PrintableTable[][] = [];
	const bigProcArray: PrintableTable[][] = [];
	for (let i = 0; i < data.length; i++) {
		const wheels_tot_array = batch_array(
			data[i].wheels_tot_values.map((d) => ({
				g: data[i].groupValue,
				wheels_tot_value: d,
				proc_tot_value: { x: 'placeholder', y: 0, number_of_proc_turnovers: 0 },
			})),
			PRINTABLE_BATCH_SIZE
		);
		const proc_tot_array = batch_array(
			data[i].proc_tot_values.map((d) => ({
				g: data[i].groupValue,
				proc_tot_value: d,
				wheels_tot_value: { x: 'placeholder', y: 0, number_of_wheels_turnovers: 0 },
			})),
			PRINTABLE_BATCH_SIZE
		);

		wheels_tot_array.forEach((d) => bigWheelsArray.push(d));
		proc_tot_array.forEach((d) => bigProcArray.push(d));
	}

	const response: TurnoverData[] = [];
	const wheels_tot_values: {
		x: string | number;
		y: number;
		number_of_wheels_turnovers: number;
	}[] = [];

	for (let i = 0; i < bigWheelsArray.length; i++) {
		for (let j = 0; j < bigWheelsArray[i].length; j++) {
			wheels_tot_values.push(bigWheelsArray[i][j].wheels_tot_value);
		}
	}

	const proc_tot_values: {
		x: string | number;
		y: number;
		number_of_proc_turnovers: number;
	}[] = [];

	for (let i = 0; i < bigProcArray.length; i++) {
		for (let j = 0; j < bigWheelsArray[i].length; j++) {
			proc_tot_values.push(bigWheelsArray[i][j].proc_tot_value);
		}
	}

	for (let i = 0; i < data.length; i++) {
		response.push({ groupValue: data[i].groupValue, wheels_tot_values, proc_tot_values });
	}

	return response;
}

function batch_array(bigarray: PrintableTable[], batch_size: number) {
	const size = batch_size;
	const arrayOfArrays = [];
	for (let i = 0; i < bigarray.length; i += size) {
		arrayOfArrays.push(bigarray.slice(i, i + size));
	}
	return arrayOfArrays;
}
