import * as yup from 'yup';
import { FieldErrors, useForm } from 'react-hook-form';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import * as Tabs from '@radix-ui/react-tabs';

import {
	Accordion,
	Button,
	Checkbox,
	Datepicker,
	Dialog,
	MultiSelect,
	NumberField,
	Select,
	TextField,
	Timepicker,
	ToggleGroup,
	Tooltip,
	WorkingIndicator,
} from 'components';
import {
	FacilitySettingStatusResponse,
	getQueryError,
	handleApiResponse,
	SetArtificialFacility,
	ShortOption,
	useDeleteArtificialFacilityMutation,
	useGetFacilitySettingStatusInitQuery,
	useGetFacilitySettingStatusMutation,
	useGetHolidayOptionsQuery,
	useSetArtificialFacilityMutation,
	useSetFacilitySettingMutation,
	useSystem,
} from 'store';
import { days, getFacilityName, stringTimeToDate } from 'utils';
import { Facility, HolidayItem, UserRole } from 'models';
import { LoadingIndicator } from 'components/Select/subcomponents';
import { useToast } from 'context';
import { defaultStaffingGrid, DrawDownGrid, StaffingGridItem } from 'pages/Anesthesia/components';
import { tabContentClassname, tabTriggerClassname } from 'pages/Block';
import { convertSettingToToggleOption } from '../BlockSettings';

interface FacilitySettingAccordionItemProps {
	facility?: Facility;
	readonly?: boolean;
	fetching?: boolean;
	healthsystem_custom_holidays?: HolidayItem[];
	set_healthsystem_custom_holidays?: Dispatch<SetStateAction<HolidayItem[]>>;
	update_holidays_all_facilities?: () => void;
	delete_holidays_all_facilities?: (holidays_to_remove: (number | undefined)[]) => void;
	rebuildingArtificial: () => void;
}

export const updateFacilitySettingsSchema = yup.object({
	facility_id: yup.number().required().typeError('This value must be a number.'),
	fcots_target: yup
		.number()
		.min(5, 'This value must be greater than 5.')
		.max(100, 'This value must be less than 100.')
		.required()
		.typeError('This value must be a number.'),
	addon_rate_target: yup
		.number()
		.min(5, 'This value must be greater than 5.')
		.max(50, 'This value must be less than 50.')
		.required()
		.typeError('This value must be a number.'),
	facility_utilization_target: yup
		.number()
		.min(5, 'This value must be greater than 5.')
		.max(100, 'This value must be less than 100.')
		.required()
		.typeError('This value must be a number.'),
	turnover_target: yup
		.number()
		.min(5, 'This value must be greater than 5.')
		.max(60, 'This value must be less than 60.')
		.required()
		.typeError('This value must be a number.'),
	fcots_timestamp: yup.string().required(),
	fcots_window_time_start: yup.string().required(),
	fcots_window_time_end: yup.string().required(),
	sunday_rooms_count: yup.number().min(1, 'Must be 1 or greater').required().typeError('This value must be a number.'),
	monday_rooms_count: yup.number().min(1, 'Must be 1 or greater').required().typeError('This value must be a number.'),
	tuesday_rooms_count: yup.number().min(1, 'Must be 1 or greater').required().typeError('This value must be a number.'),
	wednesday_rooms_count: yup
		.number()
		.min(1, 'Must be 1 or greater')
		.required()
		.typeError('This value must be a number.'),
	thursday_rooms_count: yup.number().min(1, 'Must be 1 or greater').required().typeError('This value must be a number.'),
	friday_rooms_count: yup.number().min(1, 'Must be 1 or greater').required().typeError('This value must be a number.'),
	saturday_rooms_count: yup.number().min(1, 'Must be 1 or greater').required().typeError('This value must be a number.'),
	sunday_prime_time_start: yup.string(),
	sunday_prime_time_end: yup.string(),
	monday_prime_time_start: yup.string().required(),
	monday_prime_time_end: yup.string().required(),
	tuesday_prime_time_start: yup.string().required(),
	tuesday_prime_time_end: yup.string().required(),
	wednesday_prime_time_start: yup.string().required(),
	wednesday_prime_time_end: yup.string().required(),
	thursday_prime_time_start: yup.string().required(),
	thursday_prime_time_end: yup.string().required(),
	friday_prime_time_start: yup.string().required(),
	friday_prime_time_end: yup.string().required(),
	saturday_prime_time_start: yup.string(),
	saturday_prime_time_end: yup.string(),
	is_primetime_strict: yup.boolean().required(),
	primetime_weekends: yup.boolean(),
	holidays_included: yup.array().of(
		yup.object({
			id: yup.number(),
			name: yup.string(),
			selected: yup.boolean() || undefined,
			is_custom: yup.boolean() || undefined,
			created_at: yup.string() || undefined,
			to_delete: yup.boolean() || undefined,
		})
	),
	procedure_filtering_options: yup.array().of(yup.string()),
	draw_down_schedule: yup.array(),
	update_in_place_using: yup.string().nullable(),
});

// type we expect when form submitted, used to validate
export type UpdateFacilitySettingsForm = yup.InferType<typeof updateFacilitySettingsSchema>;

export function FacilitySettingsAccordionItem({
	facility,
	readonly,
	fetching,
	set_healthsystem_custom_holidays,
	update_holidays_all_facilities,
	delete_holidays_all_facilities,
	rebuildingArtificial,
}: FacilitySettingAccordionItemProps) {
	// Drop down states
	const defaultStrictnessValue = {
		label: facility?.is_primetime_strict ? 'Strict' : 'Lenient',
		value: facility?.is_primetime_strict ? '1' : '0',
	};
	const defaultFcotsTimestamp = {
		label: facility?.fcots_timestamp === 'procedure_start' ? 'Procedure Start' : 'Patient In Room',
		value: facility?.fcots_timestamp === 'procedure_start' ? 'procedure_start' : 'patient_in_room',
	};

	const [setFacilitySetting] = useSetFacilitySettingMutation();
	const holidays = useGetHolidayOptionsQuery(null);
	const { data } = useSystem();
	const isAdmin = data?.user.role === UserRole.admin;

	// stores all known options for holidays
	const [customHolidayOptions, setCustomHolidayOptions] = useState<HolidayItem[]>(
		facility?.holidays_included.filter(({ is_custom }) => is_custom) ?? []
	);

	// stores holiday options only for this session before submitting
	const [customHolidayOptionsForSession, setCustomHolidayOptionsForSession] = useState<HolidayItem[]>([]);

	const [addCustomHolidayDialogOpen, setAddCustomHolidayDialogOpen] = useState(false);
	const [deleteCustomHolidayDialogOpen, setDeleteCustomHolidayDialogOpen] = useState(false);
	const [updateAllFacilityHolidays, setUpdateAllFacilityHolidays] = useState(false);
	const [deleteAllFacilityHolidays, setDeleteAllFacilityHolidays] = useState(false);

	const { createToast } = useToast();
	const [isWorking, setIsWorking] = useState<boolean>(false);
	const [pipelineRerunning, setPipelineRerunning] = useState<boolean>(false);
	const { data: pipeline_data, refetch } = useGetFacilitySettingStatusInitQuery({
		facility_id: facility?.id,
	});
	const [customHoliday, setCustomHoliday] = useState<{
		holiday_name: string;
		holiday_date: Date;
	}>({
		holiday_name: '',
		holiday_date: new Date(),
	});

	// form state
	const {
		handleSubmit,
		setValue,
		reset,
		register,
		getValues,
		watch,
		formState: { errors, isDirty, dirtyFields },
	} = useForm<UpdateFacilitySettingsForm>({
		resolver: yupResolver(updateFacilitySettingsSchema),
		defaultValues: {
			facility_id: facility?.id,
			fcots_target: facility?.fcots_target,
			addon_rate_target: facility?.addon_rate_target,
			facility_utilization_target: facility?.facility_utilization_target,
			turnover_target: facility?.turnover_target,
			fcots_timestamp: facility?.fcots_timestamp,
			fcots_window_time_start: facility?.fcots_window_time_start,
			fcots_window_time_end: facility?.fcots_window_time_end,
			sunday_rooms_count: facility?.sunday_rooms_count,
			monday_rooms_count: facility?.monday_rooms_count,
			tuesday_rooms_count: facility?.tuesday_rooms_count,
			wednesday_rooms_count: facility?.wednesday_rooms_count,
			thursday_rooms_count: facility?.thursday_rooms_count,
			friday_rooms_count: facility?.friday_rooms_count,
			saturday_rooms_count: facility?.saturday_rooms_count,
			sunday_prime_time_start: facility?.sunday_prime_time_start,
			sunday_prime_time_end: facility?.sunday_prime_time_end,
			monday_prime_time_start: facility?.monday_prime_time_start,
			monday_prime_time_end: facility?.monday_prime_time_end,
			tuesday_prime_time_start: facility?.tuesday_prime_time_start,
			tuesday_prime_time_end: facility?.tuesday_prime_time_end,
			wednesday_prime_time_start: facility?.wednesday_prime_time_start,
			wednesday_prime_time_end: facility?.wednesday_prime_time_end,
			thursday_prime_time_start: facility?.thursday_prime_time_start,
			thursday_prime_time_end: facility?.thursday_prime_time_end,
			friday_prime_time_start: facility?.friday_prime_time_start,
			friday_prime_time_end: facility?.friday_prime_time_end,
			saturday_prime_time_start: facility?.saturday_prime_time_start,
			saturday_prime_time_end: facility?.saturday_prime_time_end,
			is_primetime_strict: !!facility?.is_primetime_strict,
			primetime_weekends: facility?.sunday_rooms_count || facility?.saturday_rooms_count ? true : false,
			holidays_included: facility?.holidays_included,
			procedure_filtering_options: facility?.procedure_filtering_options ?? [],
			draw_down_schedule: facility?.draw_down_schedule ?? defaultStaffingGrid,
			update_in_place_using: facility?.update_in_place_using ?? 'computed_case_id',
		},
		mode: 'onChange',
	});

	// need this for conditional rendering of weekends in primetime settings table, otherwise weekend rows stop being rendered
	// on checkbox events. Watches all inputs and refreshes when change detected.
	watch();

	const onSubmit = async (formData: UpdateFacilitySettingsForm) => {
		// loading state
		setIsWorking(true);

		if (updateAllFacilityHolidays && update_holidays_all_facilities) {
			update_holidays_all_facilities();
		}
		if (deleteAllFacilityHolidays && delete_holidays_all_facilities) {
			const holidays_to_remove = getValues('holidays_included')
				?.filter((elm) => elm.is_custom === true && elm.to_delete === true)
				.map(({ id }) => id);
			delete_holidays_all_facilities(holidays_to_remove ?? []);
		}

		// Zero values that were set to 1 for passing validation
		primetimeDowState.forEach(({ roomCountField, active }) => {
			if (!active) {
				formData[roomCountField] = 0;
				setValue(roomCountField, 0);
			}
		});

		if (!getValues('primetime_weekends')) {
			formData.saturday_rooms_count = 0;
			formData.sunday_rooms_count = 0;
		}

		// Only needed for rendering
		delete formData.primetime_weekends;

		const response = await setFacilitySetting(formData);

		handleApiResponse(response, {
			success: (data) => {
				if (data.pipeline_rerun_required) {
					setPipelineRerunning(true);
				} else {
					refetch();
				}
				createToast({
					title: `The facility was successfully updated.`,
					variant: 'success',
				});
				reset(
					{},
					{
						keepValues: true,
					}
				); // resets isDirty but keeps values as is
			},
			error: (error) => {
				createToast({
					title: getQueryError(error),
					variant: 'error',
				});
			},
		});

		if (set_healthsystem_custom_holidays) {
			set_healthsystem_custom_holidays([]);
			setCustomHolidayOptionsForSession([]);
			setUpdateAllFacilityHolidays(false);
		}
		if (delete_holidays_all_facilities) {
			setDeleteAllFacilityHolidays(false);
		}

		setIsWorking(false);
	};

	const primetimeInputSettings: {
		id: number;
		dayOfWeek: string;
		startField: keyof UpdateFacilitySettingsForm;
		endField: keyof UpdateFacilitySettingsForm;
		roomCountField:
			| 'sunday_rooms_count'
			| 'monday_rooms_count'
			| 'tuesday_rooms_count'
			| 'wednesday_rooms_count'
			| 'thursday_rooms_count'
			| 'friday_rooms_count'
			| 'saturday_rooms_count';
	}[] = [
		{
			id: 1,
			dayOfWeek: 'Sunday',
			startField: 'sunday_prime_time_start',
			endField: 'sunday_prime_time_end',
			roomCountField: 'sunday_rooms_count',
		},
		{
			id: 2,
			dayOfWeek: 'Monday',
			startField: 'monday_prime_time_start',
			endField: 'monday_prime_time_end',
			roomCountField: 'monday_rooms_count',
		},
		{
			id: 3,
			dayOfWeek: 'Tuesday',
			startField: 'tuesday_prime_time_start',
			endField: 'tuesday_prime_time_end',
			roomCountField: 'tuesday_rooms_count',
		},
		{
			id: 4,
			dayOfWeek: 'Wednesday',
			startField: 'wednesday_prime_time_start',
			endField: 'wednesday_prime_time_end',
			roomCountField: 'wednesday_rooms_count',
		},
		{
			id: 5,
			dayOfWeek: 'Thursday',
			startField: 'thursday_prime_time_start',
			endField: 'thursday_prime_time_end',
			roomCountField: 'thursday_rooms_count',
		},
		{
			id: 6,
			dayOfWeek: 'Friday',
			startField: 'friday_prime_time_start',
			endField: 'friday_prime_time_end',
			roomCountField: 'friday_rooms_count',
		},
		{
			id: 7,
			dayOfWeek: 'Saturday',
			startField: 'saturday_prime_time_start',
			endField: 'saturday_prime_time_end',
			roomCountField: 'saturday_rooms_count',
		},
	];

	const [primetimeDowState, setPrimetimeDowState] = useState(
		primetimeInputSettings
			.map(({ dayOfWeek, startField, endField, roomCountField, id }) => ({
				id,
				dayOfWeek: dayOfWeek,
				active: !!facility?.[roomCountField],
				startField,
				endField,
				roomCountField,
			}))
			.filter((element) => !['Sunday', 'Saturday'].includes(element.dayOfWeek)) ?? []
	);

	const selected_holidays =
		getValues('holidays_included')?.map(({ id, name, selected, to_delete }) => ({
			value: id,
			label: name,
			selected,
			to_delete,
		})) ?? [];
	const custom_holidays_to_remove: {
		value: number | undefined;
		label: string | undefined;
		to_delete?: boolean | undefined;
	}[] =
		getValues('holidays_included')
			?.filter((elm) => elm.is_custom === true)
			.map(({ id, name, to_delete }) => ({ value: id, label: name, to_delete })) ?? [];
	const is_artificial = facility?.name.includes('*');

	// used for artificial facilities
	const [deleteFacility] = useDeleteArtificialFacilityMutation();
	const [setFacility] = useSetArtificialFacilityMutation();

	const rebuildArtificialFacility = async () => {
		// deletes artificial facility
		const deleteResponse = await deleteFacility({
			facility_id: facility?.id,
			refresh_cache: false,
		});
		handleApiResponse(deleteResponse, {
			success: () => {
				rebuildFacility({
					healthsystem_id: facility?.healthsystem_id ? facility?.healthsystem_id : null,
					name: facility?.name ? facility?.name.replace('*', '') : '',
					children: 'data' in deleteResponse ? deleteResponse.data.children : [],
					previous_facility_settings: 'data' in deleteResponse ? deleteResponse.data.last_settings : undefined,
				});
			},
			error: (error) => {
				createToast({
					title: getQueryError(error),
					variant: 'error',
				});
			},
		});
	};

	const rebuildFacility = async (payload: SetArtificialFacility) => {
		// creates it again with new settings
		const response = await setFacility(payload);
		handleApiResponse(response, {
			success: (data) => {
				setIsWorking(false);
			},
			error: (error) => {
				createToast({
					title: getQueryError(error),
					variant: 'error',
				});
			},
		});

		setIsWorking(false);
	};

	// keep custom holidays updates
	useEffect(() => {
		if (updateAllFacilityHolidays && set_healthsystem_custom_holidays) {
			set_healthsystem_custom_holidays(customHolidayOptionsForSession);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customHolidayOptionsForSession]);

	const holiday_option_ids = holidays.data?.holidays
		.map(({ id }) => id)
		.concat(customHolidayOptions.filter((holiday) => holiday.to_delete === false).map(({ id }) => id));

	const [grid, setGrid] = useState<StaffingGridItem[]>(facility?.draw_down_schedule ?? defaultStaffingGrid);

	// keep draw down grid updated
	useEffect(() => {
		setValue('draw_down_schedule', grid, { shouldDirty: true });

		// used to pass front-end input checks
		const state = primetimeDowState.map((elem) => {
			const temp = elem;

			// update room value
			if (!temp.active) {
				setValue(temp.roomCountField, 1, { shouldDirty: true });
			}

			return temp;
		});

		setPrimetimeDowState(state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [grid]);

	useEffect(() => {
		const active_days_of_week = primetimeDowState
			.filter((elem) => elem.active)
			.map(({ dayOfWeek, id }) => dayOfWeek)
			.concat(getValues('primetime_weekends') ? ['Saturday', 'Sunday'] : []);
		const unactive_dows = days.filter((days) => !active_days_of_week.includes(days));
		setGrid((prevState) => {
			// Create a deep copy of the state, copying each individual object
			const newState = prevState.map((item) => ({ ...item }));
			unactive_dows.forEach((dow) => {
				newState.forEach((item) => {
					item[dow as keyof StaffingGridItem] = 0; // modify the copy
				});
			});
			return newState; // return the modified copy
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [primetimeDowState, getValues('primetime_weekends')]);

	return (
		<Accordion type='multiple' itemStyle={is_artificial ? 'dark' : 'contained'} className='mb-2'>
			<Accordion.Item value={facility ? getFacilityName(facility) : ''}>
				<form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
					<section>
						<p className='font-semibold pb-5 uppercase'>Targets</p>

						<div className='grid lg:grid-cols-2 lg:w-2/4 gap-y-5 sm:grid-cols-1 sm:max-w-max'>
							<div>
								<p className='font-semibold text-h6 py-6'>First Case On-Time Starts Target</p>
							</div>
							<div>
								<NumberField
									label='Target Percentage (%)'
									min={5}
									max={100}
									step={5}
									defaultValue={getValues('fcots_target')}
									errorMessage={errors.fcots_target?.message}
									{...register('fcots_target')}
									disabled={readonly}
								/>
							</div>

							<div>
								<p className='font-semibold text-h6 py-6'>First Case On-Time Starts Window</p>
							</div>
							<div className='flex justify-between z-10'>
								<div className='mr-10 flex flex-col'>
									<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Start</p>
									<Timepicker
										interval={15}
										minHour={6}
										maxHour={9}
										maxMinute={45}
										// label='Start'
										onChange={(time) => {
											setValue('fcots_window_time_start', time.military, { shouldDirty: true });
										}}
										selected={stringTimeToDate(getValues('fcots_window_time_start'))}
										disabled={readonly || is_artificial}
									/>
								</div>

								<div className='flex flex-col'>
									<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>End</p>
									<Timepicker
										interval={15}
										minHour={
											parseInt(
												`${getValues('fcots_window_time_start').split(':')[0]}${getValues('fcots_window_time_start').split(':')[1]}`
											) > 800
												? 9
												: 7
										}
										maxHour={10}
										// label='Start'
										onChange={(time) => {
											setValue('fcots_window_time_end', time.military, { shouldDirty: true });
										}}
										selected={stringTimeToDate(getValues('fcots_window_time_end'))}
										disabled={readonly || is_artificial}
									/>
								</div>
							</div>
							<div>
								<p className='font-semibold text-h6 py-6'>First Case On-Time Starts Timestamp</p>
							</div>
							<div>
								{!is_artificial ? (
									<Select
										label='Timestamp By'
										options={[
											{
												label: 'Patient In Room',
												value: 'patient_in_room',
											},
											{
												label: 'Procedure Start',
												value: 'procedure_start',
											},
										]}
										onChange={(opt) => {
											if (opt) {
												setValue('fcots_timestamp', opt.value, { shouldDirty: true });
											}
										}}
										defaultValue={defaultFcotsTimestamp}
										disabled={readonly || is_artificial}
									/>
								) : (
									<p className='text-p3 text-gray-400 py-1 italic pt-6 w-full'>
										Combined facilities use on the timestamp setting from each respective child facility included in the
										combination. For example: if Facility A’s FCOTS timestamp is Patient In Room, and Facility B’s FCOTS timestamp
										is Procedure start, *Facility C = Facility A + Facility B records will be Patient In Room (A) and Procedure
										Start (B), respectively.
									</p>
								)}
							</div>

							<div>
								<p className='font-semibold text-h6 py-6'>Turnover Time Target</p>
							</div>
							<div>
								<NumberField
									label='Target Time (Minutes)'
									min={5}
									max={60}
									step={5}
									defaultValue={getValues('turnover_target')}
									errorMessage={errors.turnover_target?.message}
									{...register('turnover_target')}
									disabled={readonly}
								/>
							</div>

							<div>
								<p className='font-semibold text-h6 py-6'>Facility Utilization Target</p>
							</div>
							<div>
								<NumberField
									label='Target Percentage (%)'
									min={5}
									max={100}
									step={5}
									defaultValue={getValues('facility_utilization_target')}
									errorMessage={errors.facility_utilization_target?.message}
									{...register('facility_utilization_target')}
									disabled={readonly}
								/>
							</div>

							<div>
								<p className='font-semibold text-h6 pt-6'>Facility Add-On Rate Target</p>
								<p className='text-p3 text-gray-400 py-1 italic w-10/12'>
									The target add-on rate is the quotient of add-ons and total case volume. Typically, a lower add-on rate can
									signify higher daily predictability, as add-ons are often a source of unplanned schedule variation.
								</p>
							</div>
							<div>
								<p className='pt-5 text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Target Percentage (%)</p>
								<p className='text-p3 text-gray-400 italic'>Merlin&apos;s recommended target is 10% or below.</p>
								<NumberField
									label=''
									min={5}
									max={50}
									step={5}
									defaultValue={getValues('addon_rate_target')}
									errorMessage={errors.addon_rate_target?.message}
									{...register('addon_rate_target')}
									disabled={readonly}
								/>
							</div>

							<div>
								<p className={`font-semibold text-h6 pt-6 ${!is_artificial ? 'text-black' : 'text-green-600'}`}>
									Primetime Settings
								</p>
								<p className='text-p3 text-gray-400 py-1 italic w-10/12'>
									{!is_artificial
										? `This change will impact your Facility Utilization Report. You can adjust your primetime window and available
									rooms to adjust available minutes depending on the day of the week.`
										: `For combined facilities, the primetime start window takes the earliest primetime start value and the latest primetime end value by day of week from all respective child facilities. Staffed rooms during primetime is the combination of all values from all respective child facilities by day of week. If there are weekend days included for any child facility, it will be counted in the combined facility. If you wish to change the total number of staffed rooms for the combined facility, you must change the number of staffed rooms for child facilities such that they sum up to the desired number of rooms.`}
								</p>
							</div>
							<div>
								<div className='flex justify-between w-full items-end pt-5 mb-5'>
									<div className='whitespace-nowrap mr-5'>
										<MultiSelect
											label='Days of Week Included'
											options={primetimeDowState.map(({ dayOfWeek, id }) => ({ value: id, label: dayOfWeek }))}
											onChange={(selections) => {
												const selectedDow = selections.map((elem) => elem.label);
												const state = primetimeDowState.map((elem) => {
													const temp = elem;
													temp.active = selectedDow.includes(temp.dayOfWeek);

													// update room value
													if (!temp.active) {
														setValue(temp.roomCountField, 1, { shouldDirty: true });
													}

													return temp;
												});

												setPrimetimeDowState(state);
											}}
											disabled={readonly || is_artificial}
											value={primetimeDowState
												.filter((elem) => elem.active)
												.map(({ dayOfWeek, id }) => ({ value: id, label: dayOfWeek }))}
										/>
									</div>
									<Checkbox
										className='whitespace-nowrap mb-1'
										disabled={readonly || is_artificial}
										label='Include Weekends'
										{...register('primetime_weekends')}
									/>
								</div>

								<div className='pt-3 z-0 w-fit rounded-sm pl-0 overflow-x-auto mt-1 mb-8'>
									<div className='flex items-end'>
										<DrawDownGrid
											grid={grid}
											setGrid={setGrid}
											active_days_of_week={primetimeDowState
												.filter((elem) => elem.active)
												.map(({ dayOfWeek, id }) => dayOfWeek)
												.concat(getValues('primetime_weekends') ? ['Saturday', 'Sunday'] : [])}
										/>
									</div>
								</div>
							</div>

							<div>
								<p className={`font-semibold text-h6 pt-6 ${!is_artificial ? 'text-black' : 'text-green-600'}`}>
									Primetime Classification Strictness
								</p>
								<p className='text-p3 text-gray-400 py-1 italic w-10/12'>
									{!is_artificial ? (
										<span>
											Strict classifies cases with &gt; 80% of their total case minutes in primetime as &quot;primetime&quot;.
											Lenient classifies cases with &gt; 20% of their total case minutes in primetime as &quot;primetime&quot;.
										</span>
									) : (
										`If any child facility uses the “Lenient” setting, the combined setting will be set to “Lenient”. If you want to use the “Strict” setting for a combined facility, all child facilities must use the “Strict” setting.`
									)}
								</p>
							</div>
							<div className='pt-6 z-0'>
								<Select
									label='Level of strictness'
									options={[
										{
											label: 'Lenient',
											value: '0',
										},
										{
											label: 'Strict',
											value: '1',
										},
									]}
									defaultValue={defaultStrictnessValue}
									onChange={(opt) => {
										if (opt) {
											setValue('is_primetime_strict', !!parseInt(opt.value), { shouldDirty: true });
										}
									}}
									disabled={readonly || is_artificial}
								/>
							</div>
							<div>
								<p className='font-semibold text-h6 pt-6'>Holidays</p>
								<p className='text-p3 text-gray-400 py-1 italic w-10/12'>
									Please select the holidays that should be included in the data for your facility (ex: if the cases done on New
									Years Eve should be included in the data, then select New Years Eve).{' '}
									<strong>By default, unselected holidays will be excluded from reports.</strong>
								</p>
							</div>
							<div className='pt-6 z-0'>
								<div className='flex items-end w-[37rem]'>
									<MultiSelect
										label='Holidays Included'
										sizeX={'lg'}
										options={(holidays.data?.holidays.map(({ id, name }) => ({ value: id, label: name })) ?? []).concat(
											customHolidayOptions
												.map(({ id, name }) => ({ value: id, label: name }))
												.concat(
													facility?.holidays_included
														.filter((elm) => !holiday_option_ids?.includes(elm.id) && elm.to_delete === false)
														.map(({ id, name }) => ({ value: id, label: name })) ?? []
												)
										)}
										onChange={(selections) => {
											const selectedHolidays = selections.map(({ value: id, label: name }) => ({
												is_custom: (id ?? 1) > 1000, // the id of custom holidays is epoch time, which must be higher than 1 billon
												id,
												name,
												selected: true,
												to_delete: false,
												created_at: `${Date.now()}`,
											}));

											// add back custom holidays but unselected ones
											const ids_selected = selectedHolidays.map(({ id }) => id);
											customHolidayOptions.forEach(({ id, name, to_delete }) => {
												if (!ids_selected.includes(id) && to_delete === false) {
													selectedHolidays.push({
														is_custom: true,
														id,
														name,
														selected: false,
														created_at: `${Date.now()}`,
														to_delete: false,
													});
												}
											});

											setValue('holidays_included', selectedHolidays, { shouldDirty: true });
										}}
										value={selected_holidays
											.filter((obj) => obj.selected === true && obj.to_delete === false)
											.map(({ value, label }) => ({ value, label }))}
										isWorking={holidays.isFetching}
									/>
									<Dialog
										sizeX='sm'
										title='Add Custom Holiday'
										subtitle='Add a date that is not already in the holidays list'
										open={addCustomHolidayDialogOpen}
										onOpenChange={async (isBeingOpened) => {
											if (isBeingOpened) {
												setAddCustomHolidayDialogOpen(true);
												return;
											} else {
												setAddCustomHolidayDialogOpen(false);
												return;
											}
										}}
										trigger={
											<Button className='ml-2' variant='primary-ghost' disabled={isWorking}>
												Add Custom Holiday
											</Button>
										}
										actionButtons={[
											{
												children: 'Add',
												disabled: customHoliday.holiday_name.length < 1,
												onClick: () => {
													setCustomHolidayOptions([
														...customHolidayOptions,
														{
															is_custom: true,
															id: customHoliday.holiday_date.getTime(),
															name: `${customHoliday.holiday_name} [${format(customHoliday.holiday_date, 'MM/dd/yyyy')}]`,
															selected: false,
															created_at: `${Date.now()}`,
														},
													]);

													setCustomHolidayOptionsForSession([
														...customHolidayOptionsForSession,
														{
															is_custom: true,
															id: customHoliday.holiday_date.getTime(),
															name: `${customHoliday.holiday_name} [${format(customHoliday.holiday_date, 'MM/dd/yyyy')}]`,
															selected: false,
															created_at: `${Date.now()}`,
														},
													]);

													setValue(
														'holidays_included',
														[
															...(getValues('holidays_included') ? (getValues('holidays_included') as []) : []),
															{
																is_custom: true,
																id: customHoliday.holiday_date.getTime(),
																name: `${customHoliday.holiday_name} [${format(customHoliday.holiday_date, 'MM/dd/yyyy')}]`,
																selected: false,
																created_at: `${Date.now()}`,
																to_delete: false,
															},
														],
														{
															shouldDirty: true,
														}
													);

													setAddCustomHolidayDialogOpen(false);
												},
											},
											{
												children: 'Cancel',
												onClick: () => setAddCustomHolidayDialogOpen(false),
											},
										]}
									>
										<TextField
											label='Holiday Name'
											onInput={(e: React.KeyboardEvent<HTMLInputElement>) => {
												setCustomHoliday((prevState) => {
													return { ...prevState, holiday_name: (e.target as HTMLTextAreaElement).value };
												});
											}}
											placeholder={'Add custom holiday name'}
										/>
										<p className={'px-4 pl-0 text-p3 text-gray-700 tracking-wider uppercase font-secondary mt-4'}>
											Date of Holiday
										</p>
										<Datepicker
											type='date'
											popoverContentProps={{ side: 'left' }}
											onApply={(newDate) => {
												setCustomHoliday((prevState) => {
													return { ...prevState, holiday_date: newDate };
												});
											}}
											selected={customHoliday?.holiday_date ? customHoliday.holiday_date : new Date()}
											customLimit={{ minLimit: false, maxLimit: false }}
										>
											<div className='flex justify-start border rounded-sm border-gray-500 p-1 cursor-pointer text-p3 mt-2'>
												<div className='rounded-sm text-white bg-blue-500 py-1 px-6'>
													{format(customHoliday?.holiday_date ? customHoliday.holiday_date : new Date(), 'EEEE, MMMM dd yyyy')}
												</div>
											</div>
										</Datepicker>
										<div className='flex items-end mt-3'>
											<Checkbox
												className=''
												label=''
												onChange={(event) => {
													if (event.target.checked) {
														setUpdateAllFacilityHolidays(true);
													} else {
														setUpdateAllFacilityHolidays(false);
													}
												}}
												defaultChecked={updateAllFacilityHolidays}
											/>
											<p className='whitespace-nowrap ml-1 mb-1.5 text-p3 text-gray-700 tracking-wider uppercase font-secondary'>
												Add to all facilities
											</p>
										</div>
									</Dialog>
									<Dialog
										sizeX='sm'
										title='Delete Custom Holiday'
										subtitle='Delete a date that has been added as a custom holiday'
										open={deleteCustomHolidayDialogOpen}
										onOpenChange={async (isBeingOpened) => {
											if (isBeingOpened) {
												setDeleteCustomHolidayDialogOpen(true);
												return;
											} else {
												setDeleteCustomHolidayDialogOpen(false);
												return;
											}
										}}
										trigger={
											<Button className='ml-2' variant='primary-ghost' disabled={isWorking}>
												Delete Custom Holiday
											</Button>
										}
										actionButtons={[
											{
												children: 'Delete',
												disabled: custom_holidays_to_remove.filter((obj) => obj.to_delete === true).length < 1,
												onClick: () => {
													setCustomHolidayOptions(customHolidayOptions.filter((holiday) => holiday.to_delete === false));

													setCustomHolidayOptionsForSession(customHolidayOptions.filter((holiday) => holiday.to_delete === false));

													setDeleteCustomHolidayDialogOpen(false);
												},
											},
											{
												children: 'Cancel',
												onClick: () => setDeleteCustomHolidayDialogOpen(false),
											},
										]}
									>
										{' '}
										<MultiSelect
											label='Custom Holidays'
											sizeX={'lg'}
											options={
												facility?.holidays_included
													.map(({ id, name }) => ({ value: id, label: name }))
													.filter((elm) => !holiday_option_ids?.includes(elm.value)) ?? []
											}
											onChange={(selections) => {
												const selectedHolidays = selections.map(({ value: id, label: name }) => ({
													is_custom: true, // the id of custom holidays is epoch time, which must be higher than 1 billon
													id,
													name,
													selected: false,
													created_at: `${Date.now()}`,
													to_delete: true,
												}));
												setValue('holidays_included', selectedHolidays, { shouldDirty: true });
											}}
											value={custom_holidays_to_remove
												.filter((obj) => obj.to_delete === true)
												.map(({ value, label }) => ({ value, label }))}
											isWorking={holidays.isFetching}
										/>
										<div className='flex items-end mt-3'>
											<Checkbox
												className=''
												label=''
												onChange={(event) => {
													if (event.target.checked) {
														setDeleteAllFacilityHolidays(true);
													} else {
														setDeleteAllFacilityHolidays(false);
													}
												}}
												defaultChecked={deleteAllFacilityHolidays}
											/>
											<p className='whitespace-nowrap ml-1 mb-1.5 text-p3 text-gray-700 tracking-wider uppercase font-secondary'>
												Delete from all facilities
											</p>
										</div>
									</Dialog>
								</div>

								{selected_holidays.length > 0 && dirtyFields['holidays_included'] && (
									<div className='bg-yellow-100 flex p-2 rounded-md mt-2 justify-between py-3'>
										<span className='material-symbols-outlined text-yellow-500 pr-2'>warning</span>
										<p className='text-p3 text-gray-500'>
											Unselected holidays will not be included in any reports on Merlin. This could cause discrepancies between
											numbers reported in Merlin and other reports produced by your hospital or health system.
											<br></br>
											<br></br>
											<strong>Note:</strong> If any included/checked holidays that contain block release were excluded/unchecked,
											the associated release(s) will be undone. This will have no impact on Block Utilization as the entire day
											will be excluded from the calculation.
										</p>
									</div>
								)}
							</div>
							<div className='pt-6 z-0'>
								<div className='flex items-end w-[37rem]'>
									<div className=''>
										<p className='font-bold text-p2'>Update case data in place using</p>
										<p className='mt-1 text-p3 text-gray-400 italic'>
											When processing data, if cases already exist for a given patient in room and surgeon, the most recent data
											will overwrite the old data using the specified field.
										</p>
									</div>
									<div>
										<ToggleGroup
											label='Update case data in place'
											hideLabel
											className='w-80'
											options={[
												{ label: 'Computed Case ID', value: 'computed_case_id' },
												{ label: 'Client Provided Case ID', value: 'client_provided_case_id' },
											]}
											defaultValue={getValues('update_in_place_using') ?? 'computed_case_id'}
											onValueChange={(selected) => {
												setValue('update_in_place_using', selected, {
													shouldDirty: true,
												});
											}}
										/>
									</div>
								</div>
							</div>
							{/* <div>
								<p className='font-semibold text-h6 pt-6'>Procedure Filtering Options</p>
								<p className='text-p3 text-gray-400 py-1 italic w-10/12'>
									If you would like to globally filter out a specific procedure description, use this setting to search for
									relevant procedure descriptions for filtering. For example, “Labor Epidural”
								</p>
							</div> */}
							<div className='pt-6 z-0'>
								<OptionsManager
									options={getValues('procedure_filtering_options') ?? []}
									addProcedure={(procedure) => {
										const procedures = getValues('procedure_filtering_options') ?? [];
										procedures.push(procedure);
										setValue('procedure_filtering_options', procedures, { shouldDirty: true });
									}}
									removeProcedure={(index) => {
										const procedures = getValues('procedure_filtering_options') ?? [];
										procedures.splice(index, 1);
										setValue('procedure_filtering_options', procedures, { shouldDirty: true });
									}}
								/>
							</div>
						</div>
					</section>

					<div className='flex flex-col items-end'>
						<div className='flex justify-end pt-8'>
							{is_artificial &&
								(isWorking ? (
									<div className='h-4 w-4 pr-16 self-center'>
										<LoadingIndicator />
									</div>
								) : (
									<Tooltip
										content={
											'Use this if the child facilities have had their settings changed. This will update the FCOTS window start/end and Primetime settings.'
										}
									>
										<p
											className='text-yellow-600 text-p3 cursor-pointer underline pr-5 whitespace-nowrap self-center'
											onClick={() => {
												setIsWorking(true);

												// for smoother close
												setTimeout(() => {
													rebuildArtificialFacility();
													rebuildingArtificial();
												}, 2000);
											}}
										>
											Rebuild Facility
										</p>
									</Tooltip>
								))}
							{isDirty && (
								<div className='bg-blue-100 flex p-2 rounded-md justify-between items-center mr-4 pr-4'>
									<span className='material-symbols-outlined text-blue-500 pr-2'>info</span>
									<p className='text-p3 text-gray-500'>
										It may take several minutes (30-60 minutes) for changes to take effect.
									</p>
								</div>
							)}
							<Button
								variant={!is_artificial ? 'primary' : 'tertiary-ghost'}
								sizeX='md'
								sizeY='md'
								type='submit'
								disabled={!isDirty || (grid.at(-1)?.endMinute ?? 0) < 1425}
								isWorking={isWorking}
								onClick={() => {
									// Values must at least be 1, these values are dropped in the onSubmit function
									primetimeDowState.forEach(({ roomCountField, active }) => {
										if (!active && getValues(roomCountField) === 0) {
											setValue(roomCountField, 1);
										}
									});
									// same goes for weekends
									if (!getValues('primetime_weekends')) {
										setValue('sunday_rooms_count', 1);
										setValue('saturday_rooms_count', 1);
									}
								}}
							>
								Save
							</Button>
						</div>
						<LastUpdatedBadge
							facility_id={facility?.id}
							pipeline_started={pipelineRerunning || (!pipeline_data?.run_completed && pipeline_data?.run_exists)}
							pipeline_running={setPipelineRerunning}
							refetchPipeline={refetch}
							previous_pipeline_date={pipeline_data?.date_of_run}
							toast={createToast}
						/>
					</div>
				</form>
			</Accordion.Item>
		</Accordion>
	);
}

function LastUpdatedBadge(props: {
	facility_id: number | undefined;
	pipeline_started: boolean | undefined;
	previous_pipeline_date?: Date | undefined | null;
	pipeline_running: (status: boolean) => void;
	refetchPipeline: () => void;
	toast: (args: { title: string; variant: 'error' | 'success' | 'info' | 'warning' | undefined }) => void;
}) {
	const [getStatus] = useGetFacilitySettingStatusMutation(); // used to get uploaded files
	const [currentData, setCurrentData] = useState<FacilitySettingStatusResponse | null>(null);

	const fetch_status = async () => {
		const response = await getStatus({ facility_id: props.facility_id, refetch_pages: true });
		handleApiResponse(response, {
			success: (data) => {
				if (data.run_completed) {
					props.pipeline_running(false);
					setCurrentData(data);
					props.refetchPipeline();
					props.toast({
						title: 'Changes processed successfully.',
						variant: 'success',
					});
				}
			},
			error: (error) => {
				props.toast({
					title: 'There was an error processing your settings.',
					variant: 'error',
				});
			},
		});
	};

	// Polling to check if pipeline is still running
	useEffect(() => {
		if (props.pipeline_started) {
			const interval = setInterval(() => {
				fetch_status();
			}, 30000);
			return () => clearInterval(interval);
		}
	});

	let latest_date: Date | null = null;
	if (currentData?.date_of_run && props.previous_pipeline_date) {
		if (currentData?.date_of_run >= props.previous_pipeline_date) {
			latest_date = currentData?.date_of_run;
		} else {
			latest_date = props.previous_pipeline_date;
		}
	} else {
		if (props.previous_pipeline_date) {
			latest_date = props.previous_pipeline_date;
		}

		if (currentData?.date_of_run) {
			latest_date = currentData?.date_of_run;
		}
	}

	return (
		<>
			{props.pipeline_started ? (
				<div className='flex items-center'>
					<div className='h-2 w-2 mr-3 opacity-50'>
						<LoadingIndicator />
					</div>

					<p className='text-p3 text-gray-400 italic pt-3 mb-0.5 mr-0.5'>
						Changes are being processed. Changes will not impact Merlin until processing is complete.
					</p>
				</div>
			) : (
				<p className='text-p3 text-gray-400 italic pt-3'>
					{latest_date &&
						`Last updated on ${new Date(latest_date).toLocaleString('en-US', {
							month: 'numeric',
							day: 'numeric',
							year: '2-digit',
							hour: 'numeric',
							minute: 'numeric',
							hour12: true,
						})}`}
				</p>
			)}
		</>
	);
}
// placeholder for procedure filtering
function OptionsManager(props: {
	options: (string | undefined)[];
	removeProcedure: (index: number) => void;
	addProcedure: (name: string) => void;
}) {
	const [procedure, setProcedure] = useState<string>('');
	return (
		<p></p>
		// <>
		// 	<div className='flex flex-col w-96'>
		// 		<div className='flex flex-wrap overflow-y-auto max-h-32 rounded-md pb-1'>
		// 			{props.options.map((option, index) => (
		// 				<div
		// 					key={index}
		// 					className='text-[0.8em] bg-blue-50 px-2 pr-0.5 py-1 mb-1.5 mr-1.5 rounded-md h-fit w-fit flex flex-nowrap align-middle items-center border border-white hover:border-blue-500 duration-100'
		// 				>
		// 					<p>{option}</p>
		// 					<span
		// 						className='material-symbols-outlined text-[1.7em] cursor-pointer text-gray-300'
		// 						onClick={() => {
		// 							props.removeProcedure(index);
		// 						}}
		// 					>
		// 						close
		// 					</span>
		// 				</div>
		// 			))}
		// 		</div>
		// 		<div className='flex items-end'>
		// 			<TextField
		// 				value={procedure}
		// 				onChange={(e) => {
		// 					setProcedure(e.target.value);
		// 				}}
		// 				className='w-64 mr-2'
		// 				label={''}
		// 				placeholder='Add a procedure filter option here'
		// 			></TextField>
		// 			<Button
		// 				className='w-20'
		// 				variant='primary-ghost'
		// 				sizeX='sm'
		// 				sizeY='sm'
		// 				onClick={() => {
		// 					props.addProcedure(procedure);
		// 					setProcedure('');
		// 				}}
		// 			>
		// 				Add
		// 			</Button>
		// 		</div>
		// 	</div>
		// </>
	);
}
